import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import ContactForm from "../components/forms/contactForm"
import BreadCrumbs from "../components/breadCrumbs";
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import Head from '../components/head'

const ContactPage = () => {

    const data = useStaticQuery(graphql`
    query {
        UI {
            dsContactUs {
                Title
                Description
                CTALinks {
                    CTA_Heading
                    CTA_Text
                    CTA_Link
                }
                Locations {
                    Country_Name
                    Locations {
                        Locations_StateName
                        Locations_Address
                        Locations_PhoneNumber
                        Locations_DirectionLink
                    }
                }
                SeoTag {
                    Meta_Title
                    Meta_Description
                }
            }    
        }
    }`)
    const contact = data.UI.dsContactUs
    
    return(
        <Layout>
            <Head SEO_Tags={contact.SeoTag} />
            <BreadCrumbs crumbs="Contact us" /> 
            <Alert />
            <section className="hero_contact tB-padd-30 BG-F9FAFE">
                <div className="container">
                    <div className="contactHero">
                        {contact.Title ? (
                            <h2>{contact.Title}</h2>
                        ) : null}                        
                    </div>
                </div>
            </section>
            <section className="contact_form tB-padd-30 BG-F9FAFE">
                <div className="container">
                    <div className="contactForm_wrapper">
                        <h3>Let’ talk</h3>
                        <ContactForm />
                    </div>
                </div>
            </section>
            <section className="tB-padd-30 BG-F9FAFE section-email">
                <div className="container">
                    <div className="row">
                        {contact.CTALinks && contact.CTALinks.map((cta, i) => (
                            <div className="col-lg-4 col-md-4 col-sm-6 emailCol" key={i}>
                                <div className="ul_email_inner">
                                    <div className="email_lable">{cta.CTA_Heading}</div>
                                    <div className="email_mail">
                                        <a href={cta.CTA_Link}>{cta.CTA_Text}</a>
                                    </div>
                                </div>
                            </div>
                        ))}                
                    </div>
                </div>
            </section>
            <section className="tB-padd-70 BG-F9FAFE section_address">
                <div className="container">
                    <div className="row">
                        {contact.Locations && contact.Locations.map((location, i) => (
                            <>
                                {location.Country_Name === 'INDIA' ? (
                                    <>
                                    <h2 className="pt-5 mt-5">{location.Country_Name}</h2>
                                    <div className="row">
                                        {location.Locations && location.Locations.map((state, i) => (
                                            <div className="col-md-4 col-address">
                                                <div className="address-col-inner">
                                                    {state.Locations_StateName ? (<h4>{state.Locations_StateName}</h4>) : null}
                                                    {state.Locations_Address ? (
                                                        <div dangerouslySetInnerHTML={{ __html: state.Locations_Address }} />
                                                    ) : null}
                                                    {state.Locations_PhoneNumber ? (
                                                        <div className="tabsPhoneNumber">{state.Locations_PhoneNumber}</div>
                                                    ) : null}
                                                    {state.Locations_DirectionLink ? (
                                                        <a target="_blank" href={state.Locations_DirectionLink} rel="noreferrer">
                                                            Get Directions
                                                        </a>
                                                    ) : null}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    </>
                                ) : (
                                    <div className="col-md-4 col-address">
                                        <div className="address-col-inner">
                                            <h2>{location.Country_Name}</h2>
                                            {location.Locations && location.Locations.map((state, i) => (
                                                <>
                                                    {state.Locations_StateName ? (<h4>{state.Locations_StateName}</h4>) : null}
                                                    {state.Locations_Address ? (
                                                        <div dangerouslySetInnerHTML={{ __html: state.Locations_Address }} />
                                                    ) : null}
                                                    {state.Locations_PhoneNumber ? (
                                                        <div className="tabsPhoneNumber">{state.Locations_PhoneNumber}</div>
                                                    ) : null}
                                                    {state.Locations_DirectionLink ? (
                                                        <a target="_blank" href={state.Locations_DirectionLink} rel="noreferrer">
                                                            Get Directions
                                                        </a>
                                                    ) : null}
                                                </>
                                            ))}                                                
                                        </div>                                        
                                    </div>
                                )}
                            </>
                        ))} 
                    </div>
                </div>
            </section>            
        </Layout>
    )

}

export default ContactPage