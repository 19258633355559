import React from 'react';
import { useForm } from "react-hook-form";
import { useMutation, gql } from '@apollo/client'
import Alert from 'react-s-alert'
 
const CREATE_CONTACT_QUERY = gql`
mutation($Name: String!, $Email: String!, $Company: String, $Phone_Number: Long, $Start_Est: String, $About_Project: String) {
    createDsContactForm(
      input: {
        data: {
          Name: $Name, 
          Email: $Email, 
          Company:$Company, 
          Phone_Number:$Phone_Number, 
          Start_Est:$Start_Est, 
          About_Project:$About_Project,
        }
      }
    ){
      dsContactForm{
        Name
      }
    }
}
`;

const AddContactForm = () => {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [addChannel, { data } ] = useMutation(CREATE_CONTACT_QUERY, {
            onCompleted(data) {
                //console.log(data)
                window.location.href = '/design-thank-you';
            },onError(errors) {
                const errorsslogin = errors.graphQLErrors[0].extensions.exception.data.message[0].messages[0].message            
                Alert.error('GRAPHQL VALIDATION FAILED!!', {
                    position: 'bottom-left'
                 });
                //console.log({errors})
            }
        }
    );

    const onSubmit = (data) => {
        addChannel({ variables: { 
            Name: data.name,             
            Email: data.email,
            Company: data.company,
            Phone_Number: data.phone_number ? data.phone_number : '0',
            Start_Est: data.how_soon_start,
            About_Project: data.about_the_project 
        } });
    }

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="form-group">
                        <input 
                            name="name" 
                            className="form-control form-margin-right" 
                            placeholder="Name"
                            {...register('name', { required: true })}
                        />
                        <span className="error_validation">
                            {errors.name?.type === 'required' && "Field is required!"}
                        </span>
                    </div>
                    <div className="form-group">
                        <input
                            name="email" 
                            className="form-control mb-0"
                            type="email"
                            placeholder="Email id"
                            {...register('email', { required: true, pattern: {
                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: 'Please enter a valid email!',
                            } })}
                        />
                        <span className="error_validation">
                            {errors.email?.type === 'required' && "Email is required!"}
                            {errors.email?.message}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <input 
                            name="company" 
                            className="form-control form-margin-right" 
                            placeholder="Company"
                            {...register('company', { required: true })}
                        />
                        <span className="error_validation">
                            {errors.company?.type === 'required' && "Field is required!"}
                        </span>
                    </div>
                    <div className="form-group">
                        <input 
                            name="phone_number" 
                            type="text"
                            className="form-control mb-0" 
                            placeholder="Phone Number"
                            {...register('phone_number', { required: false })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <input 
                            name="how_soon_start" 
                            className="form-control mb-0" 
                            placeholder="How soon do you want start?"
                            {...register('how_soon_start', { required: true })}
                        />
                        <span className="error_validation">
                            {errors.how_soon_start?.type === 'required' && "Field is required!"}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group textArea_style">
                        <textarea 
                            placeholder="About the project" 
                            name="about_the_project" 
                            className="form-control"
                            {...register('about_the_project', { required: true })}
                        >
                        </textarea>  
                        <span className="error_validation">
                            {errors.about_the_project?.type === 'required' && "Field is required!"}
                        </span>        
                    </div>
                </div>
                <div className="form-group">
                    <input type="submit" value="Schedule My Custom Consultation" />
                </div>
            </form>
        </>
    )
}

export default AddContactForm;